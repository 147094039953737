import React from "react"
import styles from "../styles/loader.module.scss"
import star from "../svg/bocado-star.svg"

const Loader = () => (
  <div className={styles.loaderBackground}>
    <img
      src={star}
      alt="Stella Artois"
      className={styles.loaderWatermark}
    />
  </div>
)

export default Loader